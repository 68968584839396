<template>
    <div v-if="currentUpdate" class="update-global-account-informations">

        <div class="update-global-account-informations-bloc">
            <h4>{{ $t('ratings.pseudo') }}</h4>
            <el-input
              v-model="currentUpdate.identity.pseudo"
              :class="{ errorInput: regexAccountDetails.pseudo }"
              maxlength="50"
              minlength="5"
              type="text"
              :placeholder="$t('ratings.pseudo_placeholder')"
            />
        </div>

        <div class="review-update-actions">
            <button
                :disabled="isLoading"
                v-loading="isLoading"
                class="update-account-btn"
                @click.prevent="updateAccountInformations()"
              >
                <span>{{ $t('settings.update_pseudo') }}</span>
              </button>
        </div>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from 'vuex';
import { ElMessage } from 'element-plus'
  
export default {
    emits: ['close'],
    props: {
      accountDetails: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        regexAccountDetails: {
            pseudo: false
        },

        isLoading: false,
        currentUpdate: null
      };
    },
    mounted() {
        this.currentUpdate = this.accountDetails;
    },
    watch: {
        accountDetails() {
            this.currentUpdate = this.accountDetails;
        }
    },
    computed: {
      ...mapGetters({
        getLists: 'getLists'
      })
    },
    methods: {
      ...mapActions({
        updateAccount: 'updateAccount'
      }),
      async updateAccountInformations() {
        const pseudoRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;

        if (!pseudoRegex.test(this.currentUpdate.identity.pseudo)) {
            this.regexAccountDetails.pseudo = true;
        } else {
            this.regexAccountDetails.pseudo = false;
        }

        if (!this.regexAccountDetails.pseudo) {
            this.isLoading = true;
            
            await this.updateAccount(this.currentUpdate).then(() => {

                this.$emit('close');
    
            }).catch(() => {
                this.regexAccountDetails.pseudo = true;
            }).finally(() => {
                this.isLoading = false;
            })

        } else {
            ElMessage({
                message: this.$t('settings.wrong_pseudo'),
                type: 'warning',
                grouping: true
            })
        }
      }
    }
  }
</script>
  
<style lang="scss">
@import '@/assets/styles/auth.scss';
  
.update-global-account-informations-bloc {
    margin-top: 10px;
}

.review-update-actions {
    display: flex;
    justify-content: center;

    .update-account-btn {
        margin-top: 20px;
        padding: 5px 8px;
        width: 100%;
        max-width: 185px;
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;
        border: none;
        color: var(--text-color);
        background: $primary-color;
    }
}
</style>