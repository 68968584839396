<template>
  <div v-if="delivery" class="update-delivery">
    <div class="update-delivery-body">
      <div class="bloc-2">
        <label for="address">
          <h4>{{ $t('register.address') }}</h4>
          <el-input :placeholder="$t('register.address_placeholder')" type="text" minlength="2" maxlength="80" clearable v-model="deliveryAddress.address" :size="size" />
        </label>
      </div>

      <div class="bloc-2 flex">
        <label for="zip">
          <h4>{{ $t('register.zipcode') }}</h4>
          <el-input :placeholder="$t('register.zipcode')" type="text" minlength="4" maxlength="7" v-model="deliveryAddress.zip" clearable :size="size"/>
        </label>
        <label for="city">
          <h4>{{ $t('register.city') }}</h4>
          <el-input :placeholder="$t('register.city')" type="text" minlength="2" maxlength="50" v-model="deliveryAddress.city" clearable :size="size"/>
        </label>
      </div>

      <div class="bloc-2 flex">
        <label for="country">
          <h4>{{ $t('register.country') }}</h4>
          <el-select :placeholder="$t('register.step_1.select')" filterable v-model="deliveryAddress.country" :size="size" :effect="darkmode ? 'dark' : ''">
            <el-option v-for="item in country" :key="item.nom_en_gb" :label="item.nom_en_gb" :value="item.alpha2" class="options-index" />
          </el-select>
        </label>
        <label for="region">
          <h4>{{ $t('register.region') }}</h4>
          <el-select
            v-if="deliveryAddress.country === 'US'"
            :placeholder="$t('register.region')"
            filterable
            v-model="deliveryAddress.region"
            :size="size"
            :effect="darkmode ? 'dark' : ''"
          >
            <el-option
              v-for="item in getLists.states_us"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-else-if="deliveryAddress.country === 'CA'"
            :placeholder="$t('register.region')"
            filterable
            v-model="deliveryAddress.region"
            :size="size"
            :effect="darkmode ? 'dark' : ''"
          >
            <el-option
              v-for="item in getLists.states_ca"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-input v-else :placeholder="$t('register.region')" type="text" minlength="2" maxlength="50" v-model="deliveryAddress.region" clearable :size="size"/>
        </label>
      </div>
    </div>
    <button @click="updateDeliveryAddress()" class="generic-btn register-update">{{ $t('settings.update_dialog.register') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { inject } from "vue"

export default {
  emits: ['close'],
  props: {
    delivery: {
      type: Boolean,
      required: false,
      default: false
    },
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      darkmode: inject("darkmode"),
      deliveryAddress: {
        address: null,
        zip: null,
        city: null,
        region: null,
        country: null,
        is_reference: false
      },

      size: 'large'
    };
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists',
      getUserDetails: 'getUserDetails'
    }),
    country() {
      return this.getLists?.country;
    }
  },
  watch: {
    'deliveryAddress.country': function () {
      this.deliveryAddress.region = null;
    }
  },
  methods: {
    ...mapActions({
      changeDeliveryAddress: 'changeDeliveryAddress',
    }),
    async updateDeliveryAddress() {
      this.getUserDetails.address.delivery_multiple_address.push(this.deliveryAddress);
      const payload = {
        address: this.getUserDetails.address.delivery_multiple_address
      };
      await this.changeDeliveryAddress(payload).then(() => {
        this.deliveryAddress = {
          address: null,
          zip: null,
          city: null,
          region: null,
          country: null,
          is_reference: false
        }
        this.$emit('close');
      });
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/auth.scss';

.update-delivery {

  .update-delivery-body {
    margin: 15px 0;

    .bloc-2 {
      justify-content: space-between;
      margin-bottom: 18px;

      label {
        width: 48%;

        h4 {
          color: var(--text-color);
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }
    }
  }

  .register-update {
    width: 125px;
    height: 47px;
    margin: auto;
  }
}

.el-popper, .el-select__popper {
  z-index: 9000!important;
}
</style>