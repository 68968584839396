<template>
  <div class="invoices-history">
    <div class="invoices-header">
      <h1>{{ $t('settings.invoices.title') }}</h1>
    </div>
    <div class="invoices-tabs" v-if="invoices.length > 0">
      <el-table
        :data="returnFilterInvoices"
        stripe
        class="invoice-list hide-tel"
      >
        <el-table-column
          prop="created_at"
          :label="$t('commons.date')"
          width="120"
          sortable
        >
          <template v-slot="{ row }">
            <p style="color: white">{{ tranformDate(row.created_at) }}</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="price"
          :label="$t('commons.price_vat')"
          width="110"
          sortable
        >
          <template v-slot="{ row }">
            <p style="color: white">{{ row.price }}</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          width="100"
          :label="$t('commons.status')"
          sortable
        >
          <template v-slot="{ row }">
            <div v-if="row.status === 'paid'" class="paid">
              {{ $t('settings.invoices.paid') }}
            </div>
            <p v-else>-</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="infos"
          :label="$t('commons.subscriptions')"
          sortable
        >
          <template v-slot="{ row }">
            <div v-if="row.infos.length > 1">
              <p class="invoice-name">{{ row.infos[0].name }}</p>
            </div>
            <div v-else>
              <p
                v-for="orderType in row.infos"
                :key="row.invoice_uuid + orderType.name"
                class="invoice-name"
              >
                {{ orderType.name }}
              </p>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="download" label="" width="110">
          <template v-slot="{ row }">
            <div style="display: flex; justify-content: left">
              <button
                @click="downloadInvoice(row.invoice_uuid)"
                class="download-invoice"
              >
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('settings.invoices.download')"
                  placement="top"
                >
                  <img
                    src="@/assets/svg/v2/settings/invoiceHistory.svg"
                    class="themed-img"
                    alt="Invoice download"
                  />
                </el-tooltip>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-table
        :data="returnFilterInvoices"
        stripe
        class="invoice-list display-tel"
      >
        <el-table-column
          prop="created_at"
          :label="$t('commons.date')"
          width="110"
          sortable
        >
          <template v-slot="{ row }">
            <p style="color: white">{{ tranformDate(row.created_at) }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="infos" label="Abonnement">
          <template v-slot="{ row }">
            <div v-if="row.infos.length > 1">
              <p class="invoice-name">{{ row.infos[0].name }}</p>
            </div>
            <div v-else>
              <p
                v-for="orderType in row.infos"
                :key="row.invoice_uuid + orderType.name"
                class="invoice-name"
              >
                {{ orderType.name }}
              </p>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="download" label="" width="60" align="right">
          <template v-slot="{ row }">
            <div style="display: flex; justify-content: center">
              <button
                @click="downloadInvoice(row.invoice_uuid)"
                class="download-invoice"
              >
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('settings.invoices.download')"
                  placement="top"
                >
                  <img
                    src="@/assets/svg/v2/settings/invoiceHistory.svg"
                    class="themed-img"
                    alt="Invoice download"
                  />
                </el-tooltip>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="empty-invoice" v-else>
      <p>{{ $t('settings.invoices.empty') }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import m from 'moment'
import { filter } from 'lodash'

export default {
  data() {
    return {
      currentRow: null,
    }
  },
  computed: {
    ...mapGetters({
      invoices: 'getInvoices',
    }),
    returnFilterInvoices() {
      return filter(this.invoices, (o) => {
        return !o.infos[0].code
      })
    },
  },
  methods: {
    ...mapActions({
      getInvoices: 'getInvoicesHistory',
      getDownloadInvoice: 'downloadInvoice',
    }),
    tranformDate(date) {
      return m(date).format('DD/MM/YYYY')
    },
    downloadInvoice(invoice) {
      let payload = 'all'

      if (invoice) {
        payload = invoice
      } else {
        payload = 'all'
      }

      if (payload !== 'all') {
        this.getDownloadInvoice(payload)
      }
    },
  },
  mounted() {
    this.getInvoices()
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.invoices-header,
.download-invoice {
  display: flex;
}

.display-tel {
  display: none;
}

.invoices-history {
  margin-left: 53px !important;
  width: 100%;
  max-width: 750px;
  min-width: 600px;

  .invoices-header {
    justify-content: space-between;
    margin-bottom: 18px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      padding: 0;
    }

    button {
      cursor: not-allowed;
      border-radius: 8px;
      border: 1px solid var(--border);
      background: var(--background-color-2);
      color: var(--subtitle-text-color);
      font-size: 15px;
      font-weight: 500;
      height: 36px;
      width: 143px;
    }
  }

  .invoices-tabs {
    border-radius: 12px;
    border: 1px solid var(--border);
    background: var(--background-color-2);
    overflow: hidden;
  }
}

.paid {
  border-radius: 6px;
  border: 1px solid var(--buysuccess-color);
  color: var(--buysuccess-color);
  font-size: 14px;
  font-weight: 500;
  width: 50px;
  text-align: center;
}

.download-invoice {
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: transparent;
  box-shadow: var(--box-shadow-6);
  color: var(--text-color);
  font-size: 15px;
  font-weight: 500;
  height: 30px;
  margin-right: 4px;
}

.invoice-name {
  color: var(--text-color);
  word-break: keep-all;
}

.empty-invoice {
  p {
    color: var(--text-color);
  }
}

.el-table tr,
.el-table th.el-table__cell {
  background-color: var(--background-color-2);
  font-size: 15px;
  font-weight: 500;
}

.el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  border-bottom: 1px solid var(--border);
}

.el-table thead {
  color: var(--text-color);
}

.el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}

.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell,
.el-table--striped:hover
  .el-table__body
  tr.el-table__row--striped:hover
  td.el-table__cell {
  background: var(--background-color-11);
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: var(--background-color-3);
}

.el-table__inner-wrapper::before {
  background-color: transparent;
}

@media screen and (max-width: 500px) {
  .hide-tel {
    display: none;
  }

  .display-tel {
    display: block;
  }

  .invoices-history {
    min-width: 300px !important;
    margin-left: 0 !important;
    margin-bottom: 20px !important;
  }
}
</style>
